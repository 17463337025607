<template>
    <div class="accountWrap">
        <div class="accountTop">
            <p class="accountTitle">
                子账号设置
            </p>
            <a-button type="primary" @click="gotoHome">
                返回主页
            </a-button>
        </div>
        <div>
            <p style="text-align: right; margin-bottom: 20px">
                <a-button type="primary" @click="addSubAccount">
                    新增
                </a-button>
            </p>
            <a-table style="margin-bottom: 20px"
                     :locale="{emptyText: '暂无数据'}"
                     table-layout="auto" :columns="columns" :pagination="false" row-key="id" :data-source="data">
                <template slot="operation" slot-scope="text, record">
                    <a href="javascript:void(0);" @click="onEdit(record)">编辑</a>
                    <a href="javascript:void(0);" style="margin: 0 20px" @click="onRequire(record)">授权项目</a>
                    <a href="javascript:void(0);" @click="onDelete(record)">删除</a>
                </template>
            </a-table>
        </div>
        <!-- 新增子账户弹窗 -->
        <a-modal
            :width="800"
            :title="tip"
            ok-text="确认"
            cancel-text="取消"
            :visible="isVisible"
            @ok="onSubmit"
            @cancel="isVisible = false">
            <a-form-model
                ref="ruleForm"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
                :model="form"
                :rules="rules">
                <a-form-model-item label="账号名" prop="account">
                    <a-input
                        v-model="form.account"
                        placeholder="请输入账号名" />
                </a-form-model-item>
                <a-form-model-item label="姓名" prop="name">
                    <a-input
                        v-model="form.name"
                        placeholder="请输入姓名" />
                </a-form-model-item>
                <a-form-model-item label="手机号" prop="tel">
                    <a-input
                        v-model="form.tel"
                        placeholder="请输入手机号" />
                </a-form-model-item>
                <a-form-model-item label="组织机构" prop="type">
                    <a-select v-model="form.type" placeholder="请选择组织机构">
                        <!-- <a-select-option v-for="item in typeList" :key="item.id" :value="item.id">
                                {{ item.categoryName }}
                            </a-select-option> -->
                        <a-select-option value="1">
                            AA部门
                        </a-select-option>
                        <a-select-option value="2">
                            BB部门
                        </a-select-option>
                        <a-select-option value="3">
                            CC部门
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="密码" prop="psd">
                    <a-input
                        v-model="form.psd"
                        placeholder="请输入密码" />
                    <p style="margin: 0">
                        密码由8位数字或字母构成，必须包含大、小写字母或特殊字符
                    </p>
                </a-form-model-item>
                <a-form-model-item label="头像" prop="img">
                    <a-upload
                        name="file"
                        :multiple="true"
                        :show-upload-list="{showRemoveIcon: false}"
                        @change="handleChange">
                        <a-button> <a-icon type="upload" /> 点击上传 </a-button>
                    </a-upload>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
const columns = [
    {
        className: '',
        title: '子账号',
        dataIndex: 'node',
        key: 'id'
    },
    {
        className: '',
        title: '姓名',
        dataIndex: 'name',
        key: 'name'
    },
    {
        className: '',
        title: '手机',
        dataIndex: 'tel',
        scopedSlots: { customRender: 'tel' }
    },
    {
        className: '',
        title: '组织机构',
        dataIndex: 'pro',
        key: 'pro'
    },
    {
        className: '',
        title: '操作',
        width: '220px',
        scopedSlots: { customRender: 'operation' }
    },
]
const data = [
    {
        id: 0,
        node: '11',
        name: 'skdjis',
        tel: '13244434432',
        pro: '基地'
    }
]
export default {
    name: "Index",
    data() {
        return {
            columns,
            data,
            // 新增子账户弹窗是否可见
            isVisible: false,
            labelCol: { span: 3 },
            wrapperCol: { span: 21 },
            tip: '',
            // 子账号数据
            form: {
                account: '',
                name: '',
                tel: '',
                type: undefined,
                psd: '',
                img: ''
            },
            rules: {},
            fileList: [
                {
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: require('@/assets/img/common/user.png'),
                }
            ]
        }
    },
    methods: {
        gotoHome() {
            this.$router.push('/home')
        },
        // 编辑操作
        onEdit(val) {
            console.log(val)
            this.tip = '编辑子账户'
            this.form = {
                account: '11',
                name: 'skdjis',
                tel: '13244434432',
                type: 1,
                psd: 'dseweffer',
                img: ''
            }
            this.isVisible = true
        },
        // 授权操作
        onRequire(val) {
            console.log(val)
            let { href } = this.$router.resolve({ path: '/project-manage', query: {setNode:'Account'} })
            window.open(href, '_blank')
        },
        // 删除操作
        onDelete(val) {
            console.log(val)
            this.$confirm({
                title: '删除子账号',
                content: '请确认是否删除当前子账号',
                okText: '确定',
                centered: true,
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    this.$message.success('删除成功！')
                }
            })
        },
        // 提交子账户数据
        onSubmit() {
            this.$message.success('修改成功！')
            this.isVisible = false
        },
        // 子账户上传头像操作
        handleChange(info) {
            let fileList = [...info.fileList]
            fileList = fileList.slice(-1)
            fileList = fileList.map(file => {
                if (file.response) {
                    file.url = file.response.url
                }
                return file
            })
            this.fileList = fileList
        },
        // 新增子账号
        addSubAccount() {
            this.tip = '新增子账户'
            this.form = {}
            this.isVisible = true
        }
    }
}
</script>

<style lang="scss" scoped>
.accountWrap {
    margin: 30px 80px;
    color: #333;
    .accountTop {
        display: flex;
        justify-content: space-between;
    }
    .accountTitle {
        font-size: 20px;
        font-weight: bold;
    }
}
</style>
